<template>

    <b-modal :ref="uniqueModalRef">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i><h2 class="mx-auto my-auto">{{ $t('modal.acte.replanifier_acte') }}</h2>
        </template>
        <template slot="default">
            <div v-if="form_message !== ''" class="col-12 alert alert-warning">{{ form_message }}</div>
            {{ $t('modal.acte.date_replanification') }}.

            <b-form-group :state="dateState" label-for="date-input" invalid-feedback="La date est requise">
                <e-datepicker id="date-input" v-model="date" />
            </b-form-group>

        </template>
        <template slot="modal-footer" slot-scope="{ ok, cancel}" class="justify-content-center">
            <b-button variant="primary" @click="handleSubmit">
                <font-awesome-icon :icon="['fal', 'calendar-alt']" class="mr-1"/>{{ $t('toolbar.replanifier') }}
            </b-button>
            <b-button @click="cancel()">
                <font-awesome-icon :icon="['fal', 'times']" class="mr-1"/> {{ $t('global.annuler') }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
	export default {
        props: {
	        callback_validation : Function
		},
        data() {
            return {
                date: new Date(),
                dateState: null,
                form_message: ''
            }
        },
        methods: {
            checkFormValidity() {
                this.dateState = this.date ? true : false
                return this.dateState == true
            },

            openModal(date, actes) {
                this.form_message = ''
                if(actes.length > 5) {
                    this.form_message = this.getTrad("global.warning_lots")
                }
				this.date = date || new Date()
				this.$refs[this.uniqueModalRef].show()
            },

            closeModal() {
				this.$refs[this.uniqueModalRef].hide()
                this.$emit("ok", { date: this.date })
            },

            handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return
				}

				if (this.callback_validation) {
					this.callback_validation({ date: this.date })
				}

				// Hide the modal manually
				this.closeModal()
            }
        },
        computed: {
            uniqueModalRef() {
                return 'modal-acte-replan-'+this._uid
            }
        }
	}
</script>
